<template>
  <v-main class="main-bgc">
    <v-container fluid>
      <v-layout align-center justify-center fill-height>
        <v-row class=" d-flex justify-center">
          <v-col md="6">
            <v-card class="p-6">
              <v-card-title>
                <h3 class="text-center w-100">
                  {{ $t(`Restore my password`) }}
                </h3>
              </v-card-title>
              <v-card-text>
                <label class="label ml-3">{{ $t(`Password`) }}</label>
                <v-text-field
                  solo
                  :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="[rules.password.required]"
                  :type="showPass ? 'text' : 'password'"
                  id="password"
                  name="password"
                  :label="$t(`Password`)"
                  v-model.trim="password"
                  @click:append="showPass = !showPass"
                  required
                ></v-text-field>
                <label class="label ml-3">{{ $t(`Confirm password`) }}</label>
                <v-text-field
                  solo
                  :append-icon="showPass2 ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="[rules.passwordConfirmation.required, passwordConfirmationRule]"
                  :type="showPass2 ? 'text' : 'password'"
                  id="passwordConfirmation"
                  name="passwordConfirmation"
                  :label="$t(`Confirm password`)"
                  v-model.trim="passwordConfirmation"
                  @click:append="showPass2 = !showPass2"
                  required
                ></v-text-field>
                <v-row no-gutters v-for="error in errors" :key="error">
                  <v-col no-gutters>
                    <p class="text-center red--text">{{ error }}</p>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-row class=" d-flex justify-center">
                  <v-btn class="px-12" color="primary" type="submit" @click="restorePasswordSubmit">
                    {{ $t(`Save`) }}
                  </v-btn>
                  <!--<v-btn to="/account/settings" color="primary"> Go to account settings</v-btn>-->
                </v-row>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-layout>
    </v-container>
    <errors-snackbar :show.sync="showWarnings" :errors="warnings"> </errors-snackbar>
    <info-snackbar :show.sync="showInfo" :message="infoMessage"> </info-snackbar>
  </v-main>
</template>

<script>
import ErrorsSnackbar from "@/components/ErrorsSnackbar.vue";
import InfoSnackbar from "@/components/InfoSnackbar.vue";
import { restorePassword } from "@/api";
export default {
  components: { ErrorsSnackbar, InfoSnackbar },
  data: () => ({
    password: "",
    passwordConfirmation: "",
    showPass: false,
    showPass2: false,
    errors: [],
    showWarnings: false,
    showInfo: false,
    infoMessage: "",
    warnings: [],
    rules: {
      password: {
        required: value => !!value || this.$t("Required.")
      },
      passwordConfirmation: {
        required: value => !!value || this.$t("Required.")
      }
    }
  }),
  computed: {
    passwordConfirmationRule() {
      return () => this.password === this.passwordConfirmation || this.$t("Password must match");
    }
  },
  methods: {
    restorePasswordSubmit() {
      if (this.$route.query.reset_password_token) {
        restorePassword({
          reset_password_token: this.$route.query.reset_password_token,
          password: this.password,
          password_confirmation: this.passwordConfirmation
        })
          .then(() => {
            // eslint-disable-next-line @typescript-eslint/no-this-alias
            const self = this;
            self.showWarnings = false;
            self.infoMessage = this.$t("Password has been successfully changed");
            self.showInfo = true;
            setTimeout(function() {
              self.$router.push("/login");
            }, 2000);
          })
          .catch(err => {
            // eslint-disable-next-line @typescript-eslint/no-this-alias
            const self = this;
            self.warnings =
              err.response.status !== 500 ? err.response.data.errors : [err.response.data.error];
            self.showWarnings = true;
            setTimeout(function() {
              self.$router.push("/login");
            }, 2000);
          });
      }
    }
  },
  mounted() {
    if (!this.$route.query.reset_password_token) {
      this.$router.push("/login");
    }
  }
};
</script>

<style></style>
